import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'react-awesome-styled-grid';
import { withPrefix } from 'gatsby';
import contentstyles from './content.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Content = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          Project {
            intro
            description
            image
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={contentstyles.div2}>
        <Container className="page-content" fluid>
          {/* <div className={contentstyles.div1}> */}
          {data.site.siteMetadata.Project.map((job, key) => (
            <div className={contentstyles.div1} data-aos={'fade-left'} key={key}>
              <div
                className={contentstyles.card_project}
                key={job.intro}
                align="center"
              >
                <div
                  className={contentstyles.divOuter}
                  key={job.description}
                  align="center"

                >
                  <img
                    className={contentstyles.image}
                    src={withPrefix(job.image)}
                    alt={job.intro}
                  ></img>
                  <div style={{ display: "flex", flexDirection: "column", margin: "2%" }} className={contentstyles.divInner}>
                    <h1 className={contentstyles.h1}>
                      <b>{job.intro}</b>
                    </h1>
                    <p className={contentstyles.p}>{job.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* </div> */}
        </Container>
      </div>
    </>
  );
};
export default Content;
