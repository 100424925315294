import React from 'react';
import startstyles from './start.module.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCode, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
// import AOS from 'aos';
const Start = () => (
  <div className={startstyles.div}>
    <h1 className={startstyles.h1}>OUR PROJECTS</h1>
    <p className={startstyles.p}>
      Projects at SDS are motivated by the desire to solve major, real-life
      problems faced by the college. Ranging from database management to
      web-design, the projects cover a wide spectrum of technologies. <br />
      <br />
      Some of our projects are mentioned below:
    </p>
  </div>
);

export default Start;
