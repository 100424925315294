import React from 'react';
import Content from '../components/Content';
import Start from '../components/Start';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import Bg from '../components/parallax';

const projects = () => (
  <>
    <Bg />
    <Navbar />
    <div className="main-content">
      <Start />
      <Content />
      <Footer />
    </div>
  </>
);

export default projects;
